// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

.main-method {
  background-image:url(/views/res/imgs/main/bg_main_section_wave.jpg);
  background-repeat:no-repeat;
  background-position: left -260px bottom -230px;
  background-size:cover;
  background-color: #f8f8f8;
  @include media-query-min(lg){
    background-position: left bottom;
    background-size:contain;
  }
  .common-title-wrap{
    @include media-query-min(lg){
      margin-bottom: 45px;
    }
  }
  .main-video-wrap {
    position:relative;
    border:2px solid $g_point-theme-color;
    padding:11px 8px;
    background-color:#fff;
  }
  .methodYoutube {
    iframe{
      width:100%;
      // height:589px;
      height:578px;
    }
  }
  .main-video-thumb {
    position:absolute;
    left:8px;
    top:11px;
    right:8px;
    bottom:11px;
    background-image:url(/views/res/imgs/main/img_main_video_thumbnail.jpg);
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    .mainPlayBtn {
      position:absolute;
      left:50%;
      top:50%;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      transform:translate3d(-50%, -50%, 0);
      transition:box-shadow .4s;
      cursor: pointer;
      @include media-query-min(md){
        width: 80px;
        height: 80px;
      }
      @include media-query-min(lg){
        &:hover {
          box-shadow: 0 0 0 10px hsla(0,0%,100%,.31);
        }
      }
    }
  }
}